import { post, get } from "@/utils/request";

/**
 * 获取商品详情
 * @returns Object
 */
export const goodsContent = (data) =>
  post("/kukegoods/goods/goodsContent", data);

/**
 * 获取微信的签名
 * @returns
 */
export const getWxSign = (data) => post("/mp/common/getSign", data);

// 获取商品详情
export const getDetail = (id) => get(`/mp/common/info/${id}`);

// 获取微信外跳转Scheme
export const getUrlScheme = (data) => post("/mp/common/getUrlScheme", data);

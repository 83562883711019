import axios from "axios";
import { encryption } from "@/utils/encryption";
import { Toast } from "vant";

const md5 = require("md5");

// create an axios instance
axios.defaults.timeout = 50000;
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // 如果后续需要登陆注册 这里做些发送请求前的事情
    config.headers = {
      //  'Content-Type': 'application/x-www-form-urlencoded'
      //  'Content-Type': 'multipart/form-data'
    };
    let flag = config.url.includes("/android_version_by_h5");
    if (flag) {
      config.headers["clientType"] = "3";
    }
    config.baseURL = config.url.includes("/kukegoods")
      ? process.env.VUE_APP_BASE_API
      : flag
      ? process.env.VUE_APP_APP_API
      : process.env.VUE_APP_GATEWAY_BASE_API;
    if (
      config.url.indexOf("/book/") == 0 ||
      config.url.indexOf("/gateway/") == 0
    ) {
      config.baseURL = "/";
    }
    if (config.url.indexOf("/book/") == 0) {
      config.headers = {
        "kk-from": "kk-book-appmini",
        "content-type": "application/json;charset=utf-8",
      };
    }
    if (config.url.includes("/getBulletboxInfo")) {
      config.headers = {
        "kk-from": "kk-book-H5",
        "content-type": "application/json;charset=utf-8",
      };
    }
    //如果是题库用户 添加请求头
    if (
      config.url.indexOf("/gateway/kukeuser/index/question/user/smsLogin") == 0
    ) {
      config.headers = {
        "kk-from": "web",
        "kk-platform": "3",
      };
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 状态码 根据实际返回修改
    if (response.status == "200") {
      if (res.code != "10000") {
        if (res.msg) Toast(res.msg);
      }
      return res;
    } else {
      return Promise.reject(res.msg || "Error");
    }
  },
  (error) => {
    switch (error.response.status) {
      case 400:
        Toast(error.response.data);
        break;
    }
    return false;
    // return Promise.reject(error);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export async function get(url, params) {
  return request(url, params, undefined, "get");
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export async function post(url, data) {
  return request(url, undefined, data, "post");
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export async function del(url, params) {
  return request(url, params, undefined, "delete");
}

function request(url, params, data, method) {
  if (url.includes("/kukegoods") || url.indexOf("/gateway/") == 0) {
    let str = `${encryption(data)}&appId=c5311528363&appKey=awo6ureum8bn&time=${
      Date.parse(new Date()) / 1000
    }`;

    let mStr = md5(str).toLowerCase();

    let dataAll = {
      appId: "c5311528363",
      sign: mStr,
      time: Date.parse(new Date()) / 1000,
    };
    data ? (dataAll.bizContent = data) : (dataAll.bizContent = {});
    data = dataAll;
  }
  return new Promise((resolve, reject) => {
    service({ url, params, data, method })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
